<template>
  <div>
    <div :class="['introduction-box', showMore ? '' : 'height-attr']">
      Can-Achieve established a new business unit named CanGoChina in 2017, which specializes in international talents recruitment, mainly foreign teachers and management positions. We have more than 300 partner schools in all around China now. Their faculty positions have been filled through our professional assistance every year. CanGoChina offers the candidates totally free service in their overseas job-hunting. We also help them on their overseas career development and visa. <br><br> Utilizing Can-Achieve’s vast resources and working closely with the headquarter on this broadening global strategy, CanGoChina recruits only high-quality teaching talents directly from U.S., Canada, U.K., Australia, New Zealand, Ireland and South Africa, and prepares teachers for future teaching careers in China with academic appraisal, pre-departure training and on-going support. CanGoChina is committed to bridge the world with China, and our ultimate vision is to foster global citizenship and provide quality education on a global scale.
      <div class="mask" v-show="showMore">
        <div class="more-btn" @click="clickMore">More</div>
      </div>
    </div>
    <div class="advantages-box">
      <h1>Advantages</h1>
      <p>Direct recruitment through overseas and domestic professional talent channels. Strict compliance with legit work permit requirements. International certified professional teaching qualifications. Exclusive academic management talents. Cover different grades and different subjects. Provide meticulous and worry-free services throughout the process</p>
      <div class="advantages-card">
        <h4>Service Process based on Convenience</h4>
        <p>During the whole process which includes signing an agreement, talent recommendation, interview conduction and helping both the parties sign the employment contract, CanGoChina fully considers the requirements of the recruitment details and process, minimizing school’s time cost, labor cost and communication cost.</p>
      </div>
      <div class="advantages-card">
        <h4>Matching the Most Suitable Candidates</h4>
        <p>CanGoChina selects the most suitable candidates from our vast talents’ database and keeps finding more candidates from our own recruitment channels upon receiving the recruitment requirements from the schools to meet the schools’ requirements. In addition, CanGoChina conduct the first round of interview based on the schools’ requirements.</p>
      </div>
      <div class="advantages-card">
        <h4>Maximizing employers’ brand value</h4>
        <p>Professionalism of recruitment job is a window to show the comprehensive strength and overall style of the schools. First impression for candidates will directly affect schools’ international reputation and fame. While matching qualified and experienced candidates for our partner schools, CanGoChina is committed to help employers establish a good and professional image.</p>
      </div>
    </div>
    <div class="recruitment-box">
      <h1>CanGoChina Recruitment Process</h1>
      <div class="recruitment-card" v-for="(item,index) in list" :key="index">
        <div :class="['recruitment-title', item.show ? 'show-title' : '']" @click="unfoldContent(index)">
          <div class="title-text">
            <span class="number">{{index+1}}.</span>
            <span class="title">{{item.title}}</span>
          </div>
          <img src="@/assets/logos/down.png">
        </div>
        <div class="recruitment-content" v-show="item.show">
          <p v-for="(i,idx) in item.contentList" :key="idx">{{i}}</p>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMore: true,
      showContent: 0,
      list: [
        {
          show: false,
          title: 'Communication of the Information for Schools and Recruitment Requirements',
          contentList: ['School’s Qualification and Recruitment Requirements','1.Verify school’s license.','2.Collect school’s introduction, location, vacancy requirements, salary and benefits.','3.Make an evaluation based on the job types, vacancy number, school’s locations, school’s background, and benefits.','4.Make sure the service standards and schools requirements compatibility regarding the above information.']
        },
        {
          show: false,
          title: 'Signing the Service Agreement',
          contentList: ['1.Send the service agreement and requirement information form.','2.Sign the agreement and fill in the requirement information form.']
        },
        {
          show: false,
          title: 'Candidates Selection and Recommendation',
          contentList: ['1.Choose the suitable candidates according to schools requirements.','2.Confirm the suitability between candidates and vacancies according to the candidate’s educational background, experience, teaching certificate, personality and ability.','3.Send suitable CVs to schools.','4.CanGoChina will start to precisely find new candidates through our own recruitment channels if there are no any other suitable candidates in our system.']
        },
        {
          show: false,
          title: 'Interview and Sign with schools',
          contentList: ['1.Arrange interview time for candidates and schools.','2.Assist schools to do background check for the candidates.','3.Assist the schools and the candidates to sign the contract.']
        },
        {
          show: false,
          title: 'Follow-up service',
          contentList: ['1.Assist and guide the candidates to prepare work permit documents and get work visa.','2.Guide schools to apply work permit notification letter, work permit and residence permit for candidates','3.Other supports and assistance for schools and candidates.']
        },

      ]
    }
  },
  methods: {
    clickMore() {
      this.showMore = false
    },
    unfoldContent(i) {
      this.list.forEach((item,index) => {
        if (index === i) {
          item.show = !item.show
          return
        }
        item.show = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.introduction-box {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  width: 9.47rem;
  height: 5rem;
  font-size: 0.37rem;
  font-family: Helvetica;
  font-weight: 300;
  line-height: 0.53rem;
  color: #222222;
  .mask {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 2rem 0 0;
    background-image: linear-gradient( hsla(0,0%,100%,0),#fff 65%, #fff);
    background-image: -webkit-linear-gradient( hsla(0,0%,100%,0),#fff 65%, #fff);
    background-image: -moz-linear-gradient( hsla(0,0%,100%,0),#fff 65%, #fff);
    background-image: -o-linear-gradient( hsla(0,0%,100%,0),#fff 65%, #fff);
    .more-btn {
      width: 2.27rem;
      height: 0.83rem;
      margin: 0 auto;
      text-align: center;
      border: 1px solid #658A97;
      font-size: 0.53rem;
      font-family: Helvetica;
      font-weight: 300;
      line-height: 0.83rem;
      color: #658A97;
      opacity: 1;
    }
  }
}
.height-attr {
  height: 100%;
}
.advantages-box {
  padding: 0.8rem 0.27rem 0;
  h1 {
    text-align: center;
    font-size: 0.75rem;
    font-family: Helvetica;
    line-height: 1rem;
    color: #333333;
  }
  p {
    text-align: center;
    font-size: 0.3rem;
    font-family: Helvetica;
    font-weight: 300;
    line-height: 0.37rem;
    color: #222222;
  }
  .advantages-card {
    box-sizing: border-box;
    margin: 0.45rem auto 0;
    padding: 0.48rem 0.45rem;
    width: 9.2rem;
    background: #658A97;
    border-radius: 0.2rem;
    opacity: 1;
    h4 {
      margin-bottom: 0.35rem;
      font-size: 0.43rem;
      font-family: Helvetica;
      font-weight: bold;
      line-height: 0.43rem;
      color: #FFFFFF;
    }
    p {
      text-align: left;
      font-size: 0.37rem;
      font-family: Helvetica;
      font-weight: 300;
      line-height: 0.5rem;
      color: #FFFFFF;
    }
  }
}
.recruitment-box {
  box-sizing: border-box;
  width: 100%;
  padding: 0.85rem 0.27rem 0.59rem;
  h1 {
    text-align: center;
    font-size: 0.75rem;
    font-family: Helvetica;
    font-weight: bold;
    line-height: 0.85rem;
    color: #333333;
    margin-bottom: 0.53rem;
  }
  .recruitment-card {
    margin-bottom: 0.27rem;
    .recruitment-title {
      box-sizing: border-box;
      display:flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0.4rem 0.37rem;
      width: 9.47rem;
      background: #80CBD8;
      border-radius: 0.3rem;
      opacity: 1;
      .title-text {
        display: flex;
        align-items: center;
        span {
          font-family: Helvetica;
          font-weight: bold;
          color: #FFFFFF;
        }
        .number {
          font-size: 0.64rem;
          line-height: 0.48rem;
          margin-right: 0.2rem;
        }
        .title {
          font-size: 0.37rem;
          line-height: 0.48rem;
        }
      }
      img {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
    .show-title {
      border-radius: 0.3rem 0.3rem 0 0;
      background: #658A97;
    }
    .recruitment-content{
      box-sizing: border-box;
      margin:0 auto;
      padding: 0 0.37rem 0.3rem;
      width: 9.47rem;
      height: 100%;
      background: #658A97;
      border-radius: 0 0 0.3rem 0.3rem;
      p {
        font-size: 0.4rem;
        font-family: Helvetica;
        line-height: 0.4rem;
        color: #FFFFFF;
        margin-bottom: 0.1rem;
      }
    }
  }
}
</style>