<template>
  <div class="box">
    <!-- 顶部图片 -->
    <div class="banner">
      <img src="@/assets/imgs/serviceBackImg.jpg"/>
      <div class="big-title">
        <span>Our services</span>
      </div>
      <h5>
        Every year, foreign colleges and universities have information changes,
        especially the admission requirements,
      </h5>
      <div class="nav-tab">
        <div
          :class="['nav-box', navTag === '1' ? 'tag-active' : '']"
          @click="changeTag('1')"
        >
          Study abroad information
        </div>
        <div
          :class="['nav-box', navTag === '2' ? 'tag-active' : '']"
          @click="changeTag('2')"
        >
          Recruitment of foreign teachers
        </div>
        <div
          :class="['nav-box', navTag === '3' ? 'tag-active' : '']"
          @click="changeTag('3')"
        >
          Immigration business
        </div>
      </div>
      <div class="down-arrow">
        <img src="@/assets/logos/down.png" />
      </div>
    </div>
    <div class="hold-banner"></div>
    <!-- 手机副导航条 -->
    <div class="mobile-nav-tab">
      <ul class="menu">
        <li
          :class="[
            'mobile-nav-box',
            navTag === '1' ? 'mobile-tag-active' : '',
          ]"
          id="study"
          @click="changeTag('1')"
        >
          Study abroad information
        </li>
        <li
          :class="[
            'mobile-nav-box',
            navTag === '2' ? 'mobile-tag-active' : '',
          ]"
          id="teacher"
          @click="changeTag('2')"
        >
          Recruitment of foreign teachers
        </li>
        <li
          :class="[
            'mobile-nav-box',
            navTag === '3' ? 'mobile-tag-active' : '',
          ]"
          id="immigration"
          @click="changeTag('3')"
        >
          Immigration business
        </li>
      </ul>
    </div>
    <!-- 留学 -->
    <div class="study-content" v-show="navTag === '1'">
      <div class="pc-study">
        <div class="study-aborad w">
          <div class="study-aborad-top">
            <div class="text">
              <div class="title">
                Study abroad<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;information
              </div>
              <div class="text-point">
                <h1>
                  <b>1.</b>Share accurate entry requirement of institutions promptly
                </h1>
                <p>
                  There are frequent updates like entry requirements from all institutions every year. We aim to keep our partners posted regarding these changes and updates, so they can deliver the most accurate information to their students.
                </p>
              </div>
            </div>
            <img src="@/assets/imgs/fuwu-1.jpg" />
          </div>
          <div class="study-aborad-bottom">
            <div class="text text-two">
              <div class="text-point">
                <h1>
                  <b>2.</b>Deliver updates about study abroad policies and universities regularly
                </h1>
                <p>
                  We send out regular updates to our partners regarding the updates of study abroad policies in general, as well as updates from our partnered institutions.
                </p>
              </div>
            </div>
            <div class="text text-two text-three">
              <div class="text-point">
                <h1>
                  <b>3.</b>Analyze the latest news and policies and provide guidance
                </h1>
                <p>
                  We provide instant analysis on the latest news and policies from the popular destinations, help our partners to understand them easily and clearly. We also publish the official statistics from the authoritative international education organizations and share with our partners.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="study-application">
          <h1>Student Application</h1>
          <wplBanner/>
        </div>
        <div class="study-follow w">
          <img src="@/assets/imgs/fuwu-2.jpg" />
          <div class="follow-up">
            <h1>Post-Arrival Support</h1>
            <div class="time-line">
              <div class="line-box">
                <div class="circle"></div>
                <div class="circle circle-center"></div>
                <div class="circle"></div>
                <div class="line"></div>
              </div>
              <div class="text">
                <div>
                  <h3>Airport pick-up</h3>
                  <p></p>
                </div>
                <div class="center-box">
                  <h3>Arrangement for Home-Stay</h3>
                  <p></p>
                </div>
                <div>
                  <h3>Arrangement for guardians</h3>
                  <p>
                    We work closely with all the post-arrival companies overseas. We are able to provide student multiple choices when it comes to post-arrival support.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="study-market">
          <h1>Marketing and Promotion</h1>
          <div class="market-box">
            <img src="@/assets/imgs/fuwu-3.jpg" />
            <div>
              <h3>Provide up-to-date institution materials</h3>
              <p>
                Can-Achieve updates the marketing materials of our partnered institutions on a regular basis. For the prioritized programs, we often provide flyers and posters to our partners. We also publish different series of guidance books as well.
              </p>
            </div>
          </div>
          <div class="market-box">
            <img src="@/assets/imgs/fuwu-3.jpg" />
            <div>
              <h3>Provide professional training</h3>
              <p>
                We organize training sessions for our partners every year, so that you have a better understanding to Can-Achieve’s programs and services. We also hold conference calls and video training from time to time upon the updates or requirements from our partnered institutions.
              </p>
            </div>
          </div>
          <div class="market-box">
            <img src="@/assets/imgs/fuwu-3.jpg" />
            <div>
              <h3>Updates the news regarding institution visitors</h3>
              <p>
                Being involved of our partner institutions would normally help with the conversion rate for our partners, we will regularly update the visiting information from our partnered institutions. We also coordinate with our partners to arrange joint market promotion activities.
              </p>
            </div>
          </div>
          <div class="market-box">
            <img src="@/assets/imgs/fuwu-3.jpg" />
            <div>
              <h3>Support marketing activities from partners</h3>
              <p>
                As the official/exclusive representative of some of our partnered institutions, we actively participate in the marketing activities held by our partners. We also can invite our partnered institutions to Asia for promotion purposes upon the request from our partners.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-study">
        <mobileStudy />
      </div>
    </div>
    <!-- 外教 -->
    <div class="teacher-content" v-show="navTag === '2'">
      <div class="pc-teacher">
        <p class="text-box">
          Can-Achieve established a new business unit named CanGoChina in 2017, which specializes in international talents recruitment, mainly foreign teachers and management positions. We have more than 300 partner schools in all around China now. Their faculty positions have been filled through our professional assistance every year. CanGoChina offers the candidates totally free service in their overseas job-hunting. We also help them on their overseas career development and visa.<br />Utilizing Can-Achieve’s vast resources and working closely with the headquarter on this broadening global strategy, CanGoChina recruits only high-quality teaching talents directly from U.S., Canada, U.K., Australia, New Zealand, Ireland and South Africa, and prepares teachers for future teaching careers in China with academic appraisal, pre-departure training and on-going support. CanGoChina is committed to bridge the world with China, and our ultimate vision is to foster global citizenship and provide quality education on a global scale.
        </p>
        <div style="background: rgb(248, 248, 248)">
          <div class="advantages-box w">
            <h1>Advantages</h1>
            <p class="advise">
              Advantages: Direct recruitment through overseas and domestic professional talent channels. Strict compliance with legit work permit requirements. International certified professional teaching qualifications. Exclusive academic management talents. Cover different grades and different subjects. Provide meticulous and worry-free services throughout the process.
            </p>
            <img class="img-first" src="@/assets/imgs/fuwu-4.jpg" />
            <img class="img-secend" src="@/assets/imgs/fuwu-5.jpg" />
            <img class="img-third" src="@/assets/imgs/fuwu-6.jpg" />
            <div class="text-first text-block">
              <h3>Service Process based on Convenience</h3>
              <p>
                During the whole process which includes signing an agreement, talent recommendation, interview conduction and helping both the parties sign the employment contract, CanGoChina fully considers the requirements of the recruitment details and process, minimizing school’s time cost, labor cost and communication cost.
              </p>
            </div>
            <div class="text-secend text-block">
              <h3>Matching the Most Suitable Candidates</h3>
              <p>
                CanGoChina selects the most suitable candidates from our vast talents’ database and keeps finding more candidates from our own recruitment channels upon receiving the recruitment requirements from the schools to meet the schools’ requirements. In addition, CanGoChina conduct the first round of interview based on the schools’ requirements.
              </p>
            </div>
            <div class="text-third text-block">
              <h3>Maximizing employers’ brand value</h3>
              <p>
                Professionalism of recruitment job is a window to show the comprehensive strength and overall style of the schools. First impression for candidates will directly affect schools’ international reputation and fame. While matching qualified and experienced candidates for our partner schools, CanGoChina is committed to help employers establish a good and professional image.
              </p>
            </div>
          </div>
        </div>
        <div class="process-box w">
          <h1>CanGoChina Recruitment Process</h1>
          <div class="switch-bar">
            <ul class="topUl">
              <li class="firstLi"></li>
              <li
                :class="switchTag === '1' ? 'switch-active' : ''"
                @click="changeSwitch('1')"
              >
                Communication of the Information for Schools and Recruitment Requirements
              </li>
              <li
                :class="switchTag === '2' ? 'switch-active' : ''"
                @click="changeSwitch('2')"
              >
                Signing the Service Agreement
              </li>
              <li
                :class="switchTag === '3' ? 'switch-active' : ''"
                @click="changeSwitch('3')"
              >
                Candidates Selection and Recommendation
              </li>
              <li
                :class="switchTag === '4' ? 'switch-active' : ''"
                @click="changeSwitch('4')"
              >
                Interview and Sign with schools
              </li>
              <li
                :class="switchTag === '5' ? 'switch-active' : ''"
                @click="changeSwitch('5')"
              >
                Follow-up service
              </li>
              <li class="lastLi"></li>
            </ul>
            <ul class="bottomUL">
              <li class="firstLi"></li>
              <li
                :class="switchTag === '1' ? 'switch-active' : ''"
                @click="changeSwitch('1')"
              >
                One
              </li>
              <li
                :class="switchTag === '2' ? 'switch-active' : ''"
                @click="changeSwitch('2')"
              >
                Two
              </li>
              <li
                :class="switchTag === '3' ? 'switch-active' : ''"
                @click="changeSwitch('3')"
              >
                Three
              </li>
              <li
                :class="switchTag === '4' ? 'switch-active' : ''"
                @click="changeSwitch('4')"
              >
                Four
              </li>
              <li
                :class="switchTag === '5' ? 'switch-active' : ''"
                @click="changeSwitch('5')"
              >
                Five
              </li>
              <li class="lastLi"></li>
            </ul>
          </div>
          <div class="switch-item" v-for="(item,index) in recruitmentList" :key="index" v-show="index+1 === +switchTag">
            <img src="@/assets/imgs/fuwu-7.jpg" />
            <div class="text-item">
              <h2>{{item.title}}</h2>
              <p v-for="(i,idx) in item.contentList" :key="idx">{{i}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-teacher">
        <mobileTeacher />
      </div>
    </div>
    <!-- 移民 -->
    <div class="business-content" v-show="navTag === '3'">
      <div class="pc-immigration">
        <div class="why-box w">
          <img src="@/assets/imgs/fuwu-8.jpg" />
          <div class="theme">
            <h1>Immigration and Global Asset Allocation</h1>
            <p>Can-Achieve was found in 2008, and joined IPO in 2017, which is the biggest K12 education organization in China. Bright Scholar (NYSE:BEDU), is one of the leading global education and immigration organizations. Can-Achieve Bojiao immigration under Bright Scholar specialized in immigration sector and in serving the high-end people who intend to invest asset overseas. Our services include Overseas Immigration, Overseas Asset Investment, Global Taxes, Overseas Education and Family Inheritance, etc.</p>
            <p>Can-Achieve Bojiao Immigration is the top professional counselor team, consisting of licensed immigration consultant, former economics counselor of the Immigration Office, International CPA, experienced overseas financial analysts, who specializes in taler-made immigration and study abord planning for the high-end people. The branches of Can-Achieve Bojiao consistently offer more assistance to our clients who have successfully immigrated overseas so that they achieve a greater business.</p>
          </div>
          <div class="point-box">
            <div class="point-item" style="background: #4E6C77;">
              <h4>More Cutting-edge</h4>
              <p>The pioneer of immigration programs.</p>
              <p>Multiple projects launched.</p>
            </div>
            <div class="point-item" style="background: #658A97;">
              <h4>More comprehensive</h4>
              <p>
                Bojiao Immigration, affiliated to Can-Achieve and Bright Scholar, has been committed to overseas study application and international education for ten years, aiming to provide comprehensive education planning for every client’s children.
              </p>
            </div>
            <div class="point-item point-item-special" style="background: #6F97A5;">
              <h4>More professional</h4>
              <p>Free counseling service: provide suggestions upon bills and plans, ect.</p>
              <p>Professional advice: on-line answers from professional immigration experts.</p>
            </div>
            <div class="point-item" style="background: #80A3AF;">
              <h4>More reliable</h4>
              <p>Local service.</p>
              <p>Chinese speaker services.</p>
              <p>Overseas legal service.</p>
            </div>
          </div>
        </div>
        <div style="background: rgb(248, 248, 248);">
          <div class="immigration-box w">
            <h1>Immigration business</h1>
            <div class="country-box">
              <img src="@/assets/imgs/fuwu-7.jpg" />
              <div class="country-content">
                <div class="country-title">
                  <img src="@/assets/imgs/fuwu-usa.jpg" />
                  <span>U.S Immigration</span>
                </div>
                <p>
                  Can-achieve Bojiao Immigration provide high-quality immigration legal services to the applicant(s) from China.
                </p>
                <p>
                  Our specialized immigrant services include EB-1A (Alien of Extraordinary Ability), National Interest Waiver, PERM Labor Certification and other I-140 immigrant petitions.
                </p>
                <p>
                  We hard work to build strong relationships based on trust. We know that you want to make America your new dream home, and that always deserves our full attention and our best efforts to realize your goal.
                </p>
              </div>
            </div>
            <div class="country-box">
              <div class="country-content">
                <div class="country-title">
                  <img src="@/assets/imgs/fuwu-ca.jpg" />
                  <span>Canada Immigration</span>
                </div>
                <p>
                  Our one-stop platform provides 100+ families with the best company resources and the best school resources, in every detail of study abroad, work, immigration, housing to provide exquisite services. Successfully helped many new immigrants and overseas students to live and work in Canada.
                </p>
              </div>
              <img src="@/assets/imgs/fuwu-7.jpg" />
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-immigration">
        <mobileImmigration />
      </div>
    </div>
    <!-- 返回顶部 -->
    <div v-show="showTop" class="to-top" @click="toTop">
      <img src="@/assets/logos/top.png">
      TOP
    </div>
    <!-- 底部导航 -->
    <gqIntroduction class="gqIntroduction" />
  </div>
</template>

<script>
import mobileStudy from "./components/mobile-study.vue";
import mobileTeacher from "./components/mobile-teacher.vue";
import mobileImmigration from "./components/mobile-immigration.vue";
import wplBanner from "./components/wpl-banner.vue";
import gqIntroduction from "@/components/gq-introduction.vue";
import wplNav from "@/components/wpl-nav.vue";
export default {
  components: {
    gqIntroduction,
    wplNav,
    mobileStudy,
    mobileTeacher,
    mobileImmigration,
    wplBanner
  },
  data() {
    return {
      navTag: "1",
      switchTag: "1",
      showTop: false,
      recruitmentList: [
        {
          title: 'Communication of the Information for Schools and Recruitment Requirements',
          contentList: ['School’s Qualification and Recruitment Requirements','1.Verify school’s license.','2.Collect school’s introduction, location, vacancy requirements, salary and benefits.','3.Make an evaluation based on the job types, vacancy number, school’s locations, school’s background, and benefits.','4.Make sure the service standards and schools requirements compatibility regarding the above information.']
        },
        {
          title: 'Signing the Service Agreement',
          contentList: ['1.Send the service agreement and requirement information form.','2.Sign the agreement and fill in the requirement information form.']
        },
        {
          title: 'Candidates Selection and Recommendation',
          contentList: ['1.Choose the suitable candidates according to schools requirements.','2.Confirm the suitability between candidates and vacancies according to the candidate’s educational background, experience, teaching certificate, personality and ability.','3.Send suitable CVs to schools.','4.CanGoChina will start to precisely find new candidates through our own recruitment channels if there are no any other suitable candidates in our system.']
        },
        {
          title: 'Interview and Sign with schools',
          contentList: ['1.Arrange interview time for candidates and schools.','2.Assist schools to do background check for the candidates.','3.Assist the schools and the candidates to sign the contract.']
        },
        {
          title: 'Follow-up service',
          contentList: ['1.Assist and guide the candidates to prepare work permit documents and get work visa.','2.Guide schools to apply work permit notification letter, work permit and residence permit for candidates','3.Other supports and assistance for schools and candidates.']
        },

      ]
    };
  },
  methods: {
    changeTag(tag) {
      this.navTag = tag;
    },
    changeSwitch(tag) {
      this.switchTag = tag;
    },
    toTop() {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },
    handleScroll(){
      let scroll = document.documentElement.scrollTop||document.body.scrollTop
      this.showTop = false
      if (scroll > 500) this.showTop = true
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.navTag = this.$route.query.id;
    }
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    window.addEventListener('scroll',this.handleScroll)
  },
  watch: {
    $route(to, from) {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      this.navTag = to.query.id
    }
  },
  destroyed(){
    window.removeEventListener('scroll',this.handleScroll)
  }
};
</script>

<style lang="less" scoped>
div,
p {
  box-sizing: border-box;
}
.w {
  width: 1440px !important;
  margin: 0 auto;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 810px;
  img {
    width: 100%;
    height: 810px;
  }
  .big-title {
    position: absolute;
    top: 229px;
    width: 100%;
    text-align: center;
    font-size: 128px;
    font-family: Helvetica;
    font-weight: bold;
    color: #ffffff;
    opacity: 1;
  }
  h5 {
    position: absolute;
    top: 426px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: Helvetica;
    font-weight: 300;
    color: #d3d3d3;
    opacity: 1;
  }
  .nav-tab {
    position: absolute;
    bottom: 170px;
    width: 100%;
    // padding: 0 175px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    .nav-box {
      width: 349px;
      height: 60px;
      margin-right: 23px;
      text-align: center;
      line-height: 60px;
      border: 1px solid #d3d3d3;
      opacity: 0.5;
      border-radius: 4px;
      font-size: 23px;
      font-family: Helvetica;
      font-weight: 300;
      color: #d3d3d3;
      opacity: 1;
    }
    .tag-active {
      border: none;
      background: #80cbd8;
      color: #fff;
      opacity: 1;
    }
  }
  .down-arrow {
    position: absolute;
    bottom: 30px;
    text-align: center;
    width: 100%;
    img {
      width: 60px;
      height: 50px;
    }
  }
}
.hold-banner {
  height: 810px;
}
.mobile-nav-tab {
  display: none;
}
.study-content {
  width: 100%;
  padding-top: 150px;
  .study-aborad {
    width: 100%;
    padding-bottom: 90px;
    .text {
      .title {
        font-size: 100px;
        font-family: Helvetica;
        font-weight: bold;
        line-height: 120px;
        color: #000000;
        opacity: 1;
        margin-bottom: 77px;
      }
      .text-point {
        h1 {
          font-size: 36px;
          font-family: Helvetica;
          font-weight: normal;
          line-height: 42px;
          color: #62828f;
          opacity: 1;
          font-style: oblique;
          margin-bottom: 50px;
          b {
            font-size: 72px;
          }
        }
        p {
          font-size: 24px;
          font-family: Helvetica;
          font-weight: 300;
          line-height: 36px;
          color: #333333;
          opacity: 1;
        }
      }
    }
    .study-aborad-top {
      display: flex;
      img {
        margin-left: 119px;
        width: 617px;
        height: 759px;
      }
    }
    .study-aborad-bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 67px;
      .text-two {
        width: 664px;
      }
      .text-three {
        h1 {
          margin-bottom: 20px;
        }
      }
    }
  }
  .study-application {
    padding-top: 100px;
    padding-left: 373px;
    padding-bottom: 145px;
    background: rgb(248, 248, 248);
    h1 {
      margin-left: 79px;
      margin-bottom: 58px;
      font-size: 100px;
      font-family: Helvetica;
      font-weight: bold;
      line-height: 120px;
      color: #000000;
      opacity: 1;
    }
  }
  .study-follow {
    display: flex;
    // padding-right: 234px;
    padding-bottom: 50px;
    img {
      width: 657px;
      height: 1022px;
      margin-right: 78px;
    }
    .follow-up {
      padding-top: 98px;
      h1 {
        margin-left: 46px;
        margin-bottom: 59px;
        font-size: 75px;
        font-family: Helvetica;
        font-weight: bold;
        line-height: 90px;
        color: #000000;
        opacity: 1;
      }
      .time-line {
        display: flex;
        .line-box {
          position: relative;
          padding-top: 10px;
          width: 15px;
          margin-right: 31px;
          display: flex;
          flex-direction: column;
          .circle {
            width: 15px;
            height: 15px;
            background: #62818e;
            border-radius: 50%;
            opacity: 1;
          }
          .circle-center {
            margin: 135px 0 128px;
          }
          .line {
            width: 1px;
            height: 490px;
            background: #d8d8d8;
            position: absolute;
            top: 16px;
            left: 7.5px;
            z-index: -1;
          }
        }
        .text {
          .center-box {
            // margin: 75px 0;
            // margin-bottom: 133px;
          }
          h3 {
            font-size: 36px;
            font-family: Helvetica;
            font-weight: bold;
            // line-height: 27px;
            color: #333333;
            opacity: 1;
            margin-bottom: 23px;
          }
          p {
            min-height: 80px;
            font-size: 18px;
            font-family: Helvetica;
            font-weight: 300;
            line-height: 27px;
            color: #333333;
            opacity: 1;
          }
        }
      }
    }
  }
  .study-market {
    padding-top: 67px;
    padding-bottom: 33px;
    background: rgb(248, 248, 248);
    h1 {
      margin: 0 auto 57px;
      text-align: center;
      font-size: 75px;
      font-family: Helvetica;
      font-weight: bold;
      line-height: 90px;
      color: #000000;
      opacity: 1;
    }
    .market-box {
      margin: 0 auto 23px;
      padding: 21px 43px 21px 23px;
      width: 1084px;
      height: 282px;
      background: #4e6c77;
      opacity: 1;
      border-radius: 8px;
      display: flex;
      img {
        width: 284px;
        height: 240px;
        background: #ffffff;
        opacity: 1;
        margin-right: 49px;
      }
      h3 {
        font-size: 27px;
        font-family: Helvetica;
        font-weight: bold;
        line-height: 90px;
        color: #ffffff;
        opacity: 1;
      }
      p {
        font-size: 18px;
        font-family: Helvetica;
        font-weight: 300;
        line-height: 27px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
  .mobile-study {
    display: none;
  }
}
.teacher-content {
  padding-top: 76px;
  .text-box {
    margin: 0 auto;
    width: 1440px;
    text-align: center;
    font-size: 18px;
    font-family: Helvetica;
    font-weight: 300;
    line-height: 27px;
    color: #333333;
    opacity: 1;
    padding-bottom: 76px;
  }
  .advantages-box {
    position: relative;
    padding-top: 68px;
    height: 1652px;
    h1 {
      margin: 0 auto;
      width: 425px;
      font-size: 75px;
      font-family: Helvetica;
      font-weight: bold;
      line-height: 90px;
      color: #000000;
      opacity: 1;
      margin-bottom: 24px;
    }
    .advise {
      margin: 0 auto;
      width: 1080px;
      text-align: center;
      font-size: 18px;
      font-family: Helvetica;
      font-weight: normal;
      line-height: 23px;
      color: #959595;
      opacity: 1;
      font-style: oblique;
    }
    img {
      width: 450px;
      height: 450px;
    }
    .img-first {
      position: absolute;
      top: 309px;
      left: 0;
    }
    .img-secend {
      position: absolute;
      top: 704px;
      right: 0;
    }
    .img-third {
      position: absolute;
      left: 0;
      bottom: 113px;
    }
    .text-block {
      h3 {
        font-size: 27px;
        font-family: Helvetica;
        font-weight: bold;
        // line-height: 90px;
        color: #62828f;
        opacity: 1;
        margin-bottom: 26px;
        width: 100%;
      }
      p {
        font-size: 18px;
        font-family: Helvetica;
        font-weight: 300;
        line-height: 27px;
        color: #333333;
        opacity: 1;
        width: 100%;
        text-align: left;
      }
    }
    .text-first {
      position: absolute;
      top: 361px;
      left: 485px;
      width: 515px;
    }
    .text-secend {
      position: absolute;
      top: 704px;
      right: 475px;
      width: 422px;
    }
    .text-third {
      position: absolute;
      bottom: 250px;
      left: 485px;
      width: 465px;
    }
  }
  .process-box {
    padding: 75px 0 68px;
    h1 {
      margin: 0 auto 60px;
      width: 1079px;
      font-size: 66px;
      font-family: Helvetica;
      font-weight: bold;
      line-height: 90px;
      color: #000000;
      opacity: 1;
    }
    .switch-bar {
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          box-sizing: border-box;
          flex: 1;
          height: 50px;
          text-align: center;
          padding: 0 5px;
          cursor: pointer;
        }
        .firstLi,
        .lastLi {
          flex: 0.5;
        }
      }
      .topUl {
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 11px;
          font-family: Helvetica;
          font-weight: 300;
          line-height: 14px;
          color: #b5b5b5;
          border-bottom: 3px solid #d8d8d8;
        }
        .switch-active {
          color: #333333;
          border-bottom: 3px solid #80cbd8;
        }
      }
      .bottomUL {
        margin-bottom: 20px;
        li {
          font-size: 18px;
          font-family: Helvetica;
          font-weight: normal;
          line-height: 50px;
          color: #d8d8d8;
          border-top: 3px solid #d8d8d8;
        }
        .switch-active {
          color: #80cbd8;
          border-top: 3px solid #80cbd8;
        }
      }
    }
    .switch-item {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 383px;
        height: 383px;
        margin-right: 37px;
      }
      .text-item {
        h2 {
          margin-bottom: 34px;
          width: 597px;
          font-size: 27px;
          font-family: Helvetica;
          font-weight: bold;
          line-height: 31px;
          color: #333333;
          opacity: 1;
        }
        p {
          width: 661px;
          font-size: 18px;
          font-family: Helvetica;
          font-weight: 300;
          line-height: 23px;
          color: #333333;
          opacity: 1;
          margin-bottom: 10px;
        }
      }
    }
  }
  .mobile-teacher {
    display: none;
  }
}
.business-content {
  .why-box {
    position: relative;
    padding: 75px 0 80px 180px;
    img {
      width: 675px;
      height: 853px;
    }
    .theme {
      position: absolute;
      top: 206px;
      right: 180px;
      width: 680px;
      background: #658a97;
      opacity: 0.8;
      padding: 47px 40px 40px 67px;
      h1 {
        font-size: 40px;
        font-family: Helvetica;
        font-weight: bold;
        line-height: 40px;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 28px;
      }
      p {
        font-size: 16px;
        font-family: Helvetica;
        font-weight: 300;
        line-height: 25px;
        color: #ffffff;
        opacity: 1;
        text-indent: 16px
      }
    }
    .point-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: -62px;
      padding-right: 160px;
      z-index: 1;
      .point-item {
        width: 260px;
        height: 410px;
        border-radius: 8px;
        padding: 24px 20px 0 20px;
        h4 {
          font-size: 24px;
          font-family: Helvetica;
          font-weight: bold;
          line-height: 27px;
          color: #ffffff;
          opacity: 1;
          margin-bottom: 38px;
        }
        p {
          font-size: 18px;
          font-family: Helvetica;
          font-weight: 300;
          line-height: 27px;
          color: #ffffff;
          opacity: 1;
        }
      }
      .point-item-special {
        padding-left: 5px;
        padding-right: 5px;
        p {
          padding-left: 12px;
          padding-right: 10px;
        }
      }
    }
  }
  .immigration-box {
    padding: 48px 180px 13px 180px;
    h1 {
      font-size: 75px;
      font-family: Helvetica;
      font-weight: bold;
      line-height: 90px;
      text-align: center;
      color: #000000;
      opacity: 1;
      margin-bottom: 30px;
    }
    .country-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 65px;
      img {
        width: 383px;
        height: 383px;
      }
      .country-content {
        width: 674px;
        .country-title {
          display: flex;
          align-items: center;
          margin-bottom: 26px;
          img {
            width: 41px;
            height: 41px;
            margin-right: 15px;
          }
          span {
            font-size: 36px;
            font-family: Helvetica-BoldOblique;
            font-weight: 400;
            line-height: 19px;
            color: #648693;
            opacity: 1;
          }
        }
        p {
          font-size: 18px;
          font-family: Helvetica;
          font-weight: 300;
          line-height: 23px;
          color: #222222;
          opacity: 1;
          margin-bottom: 20px;
        }
      }
    }
  }
  .mobile-immigration {
    display: none;
  }
}
.to-top {
  display: none;
}
@media only screen and (max-width: 768px) {
  .w {
    width: 100% !important;
  }
  .box {
    padding-bottom: 2rem;
    width: 10rem;
  }
  .banner {
    position: relative;
    width: 100%;
    height: 5.61rem;
    img {
      height: 5.61rem;
    }
    .big-title {
      top: 1.23rem;
      width: 100%;
      font-size: 1.07rem;
    }
    h5 {
      top: 3.84rem;
      padding: 0 0.69rem;
      width: 8.67rem;
      font-size: 0.32rem;
    }
    .nav-tab {
      display: none;
    }
    .down-arrow {
      display: none;
    }
  }
  .hold-banner {
    display: none;
  }
  .mobile-nav-tab {
    display: block;
    height: 1.47rem;
    padding-right: 0.2rem;
    overflow: hidden;
    background: linear-gradient(270deg, #62818e 0%, #81ccd9 100%);
    .menu {
      box-sizing: border-box;
      display: flex;
      padding: 0.2rem 0;
      width: 100%;
      height: 1.47rem;
      overflow: auto;
      white-space: nowrap;
      .mobile-nav-box {
        display: inline-block;
        margin: 0 0.3rem;
        padding: 0.2rem 0.29rem;
        border-radius: 0.1rem;
        font-size: 0.37rem;
        line-height: 0.7rem;
        color: #ffffff;
        opacity: 1;
      }
      .mobile-tag-active {
        background: #fff;
        color: #62818e;
      }
    }
  }
  .study-content {
    width: 100%;
    padding-top: 0.5rem;
    .pc-study {
      display: none;
    }
    .mobile-study {
      display: block;
    }
  }
  .teacher-content {
    width: 100%;
    padding-top: 0.5rem;
    .pc-teacher {
      display: none;
    }
    .mobile-teacher {
      display: block;
    }
  }
  .business-content {
    width: 100%;
    padding-top: 0.5rem;
    .pc-immigration {
      display: none;
    }
    .mobile-immigration {
      display: block;
    }
  }
  .gqIntroduction {
    display: none;
  }
  .to-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0.53rem;
    right: 0.56rem;
    width: 1.33rem;
    height: 1.33rem;
    border-radius: 50%;
    background: #6F9FAC;
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 0.35rem;
    color: #FFFFFF;
    img {
      width: 0.54rem;
      height: 0.54rem;
    }
  }
}
</style>