<template>
  <div>
    <div class="aborad">
      <div class="aborad-img">
        <img src="@/assets/imgs/fuwu-1.jpg">
      </div>
      <div :class="['aborad-content', showMore ? '' : 'show-aborad-content']">
        <h1>1. Share accurate entry requirement of institutions promptly</h1>
        <p>There are frequent updates like entry requirements from all institutions every year. We aim to keep our partners posted regarding these changes and updates, so they can deliver the most accurate information to their students.</p>
        <h1>2. Deliver updates about study abroad policies and universities regularly</h1>
        <p>We send out regular updates to our partners regarding the updates of study abroad policies in general, as well as updates from our partnered institutions.</p>
        <h1>3. Analyze the latest news and policies and provide guidance</h1>
        <p>We provide instant analysis on the latest news and policies from the popular destinations, help our partners to understand them easily and clearly. We also publish the official statistics from the authoritative international education organizations and share with our partners.</p>
      </div>
      <div class="more-btn" v-show="showMore" @click="clickMore">More</div>
    </div>
    <div class="admission-box">
      <h1>Student Application</h1>
      <div class="admission-card" v-for="(item,index) in list" :key="index">
        <div :class="['admission-title', item.show ? 'show-title' : '']" @click="unfoldContent(index)">
          <div class="title-text">
            <span class="number">{{index+1}}.</span>
            <span class="title">{{item.title}}</span>
          </div>
          <img src="@/assets/logos/down.png">
        </div>
        <div class="admission-content" v-show="item.show">
          <p v-for="(i,idx) in item.contentList" :key="idx">{{i}}</p>
        </div>
      </div>
      <h1>Post-Arrival Support</h1>
      <div class="time-line">
        <div class="line-box">
          <div class="circle"></div>
          <div class="circle circle-center"></div>
          <div class="circle"></div>
          <div class="line"></div>
        </div>
        <div class="text">
          <div>
            <h3>Airport pick-up</h3>
            <p></p>
          </div>
          <div class="center-box">
            <h3>Arrangement for Home-Stay</h3>
            <p></p>
          </div>
          <div>
            <h3>Arrangement for guardians</h3>
            <p>
              We work closely with all the post-arrival companies overseas. We are able to provide student multiple choices when it comes to post-arrival support.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="market-box">
      <h1>Marketing and Promotion</h1>
      <div class="market-card">
        <h3>Provide up-to-date institution materials</h3>
        <p>Can-Achieve updates the marketing materials of our partnered institutions on a regular basis. For the prioritized programs, we often provide flyers and posters to our partners. We also publish different series of guidance books as well.</p>
      </div>
      <div class="market-card">
        <h3>Provide professional training</h3>
        <p>We organize training sessions for our partners every year, so that you have a better understanding to Can-Achieve’s programs and services. We also hold conference calls and video training from time to time upon the updates or requirements from our partnered institutions.</p>
      </div>
      <div class="market-card">
        <h3>Updates the news regarding institution visitors</h3>
        <p>Being involved of our partner institutions would normally help with the conversion rate for our partners, we will regularly update the visiting information from our partnered institutions. We also coordinate with our partners to arrange joint market promotion activities.</p>
      </div>
      <div class="market-card">
        <h3>Support marketing activities from partners</h3>
        <p>As the official/exclusive representative of some of our partnered institutions, we actively participate in the marketing activities held by our partners. We also can invite our partnered institutions to Asia for promotion purposes upon the request from our partners.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobileStudy',
  data() {
    return {
      showMore: true,
      showContent: 0,
      list: [
        {
          show: false,
          title: 'Provide professional study plans',
          contentList: ['Based on the enquiry and essential information provided by our partners, our marketing/consulting and admission team will provide tailored study plans for each case. This also includes guidance on the possibility of acceptance as well as the material list for each application.']
        },
        {
          show: false,
          title: 'Provide one-on-one support',
          contentList: ['Our experienced marketing/consulting team are some of the most well-trained experts in international education. They will provide multiple study solutions for complicated cases in different countries.']
        },
        {
          show: false,
          title: 'Provide the material list accurately',
          contentList: ['With over tens of thousands of cases’ experience, our dedicated team are able to share an accurate material list for each application. Students can feel assured to obtain their offer under our extremely professional support.']
        },
        {
          show: false,
          title: 'Recommend the best choices for subject and school',
          contentList: ['As we work extremely closely with our partnered institutions, we are able to recommend the best choice regarding the subject and school for each student.']
        },
        {
          show: false,
          title: 'Review and submit the applications',
          contentList: ['We have a thorough system to review all the application from our partners. Our admission team will inform our agents of the problems in the application once they have spotted them in this system. This will ensure all the application packages submitted from us will be complete and professionally presented.']
        },
        {
          show: false,
          title: 'Feedback of the result for applications',
          contentList: ['We will always keep our partners posted regarding the application status, so they can keep tracking the update.']
        },

      ]
    }
  },
  methods: {
    clickMore() {
      this.showMore = false
    },
    unfoldContent(i) {
      this.list.forEach((item,index) => {
        if (index === i) {
          item.show = !item.show
          return
        }
        item.show = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
div,
h1{
  box-sizing: border-box;
}
.aborad {
  width: 100%;
  .aborad-img {
    position: relative;
    z-index: -1;
    width: 9.09rem;
    height: 11.17rem;
    margin: 0 auto;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .aborad-content {
    margin: -3rem auto 0;
    padding: 0.45rem 0.45rem 0 ;
    z-index: 1;
    width: 9.47rem;
    height: 5rem;
    background: linear-gradient(180deg, #658A97 0%, #FFFFFF 100%);
    overflow: hidden;
    h1 {
      font-size: 0.43rem;
      font-family: Helvetica;
      font-weight: bold;
      line-height: 0.53rem;
      color: #FFFFFF;
      opacity: 1;
      margin-bottom: 0.2rem;
    }
    p {
      font-size: 0.37rem;
      font-family: Helvetica;
      font-weight: 300;
      line-height: 0.53rem;
      color: #FFFFFF;
      opacity: 1;
      margin-bottom: 0.5rem;
    }
  }
  .show-aborad-content {
    height: 100%;
    background: #658A97;
  }
  .more-btn {
    margin: 0 auto;
    width: 2.27rem;
    height: 0.83rem;
    border: 1px solid #658A97;
    text-align: center;
    line-height: 0.83rem;
    font-size: 0.53rem;
    font-family: Helvetica;
    font-weight: 300;
    color: #658A97;
  }
}
.admission-box {
  padding-top: 0.8rem;
  h1 {
    margin-bottom: 0.45rem;
    text-align: center;
    font-size: 0.75rem;
    font-family: Helvetica;
    font-weight: bold;
    line-height: 1.25rem;
    color: #333333;
  }
  .admission-card {
    margin-bottom: 0.27rem;
    .admission-title {
      box-sizing: border-box;
      display:flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0.4rem 0.37rem;
      width: 9.47rem;
      background: #80CBD8;
      border-radius: 0.3rem;
      opacity: 1;
      .title-text {
        display: flex;
        align-items: center;
        span {
          font-family: Helvetica;
          font-weight: bold;
          color: #FFFFFF;
        }
        .number {
          font-size: 0.64rem;
          line-height: 0.48rem;
          margin-right: 0.2rem;
        }
        .title {
          font-size: 0.37rem;
          line-height: 0.48rem;
        }
      }
      img {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
    .show-title {
      border-radius: 0.3rem 0.3rem 0 0;
      background: #658A97;
    }
    .admission-content{
      box-sizing: border-box;
      margin:0 auto;
      padding: 0 0.37rem 0.3rem;
      width: 9.47rem;
      height: 100%;
      background: #658A97;
      border-radius: 0 0 0.3rem 0.3rem;
      p {
        font-size: 0.4rem;
        font-family: Helvetica;
        line-height: 0.4rem;
        color: #FFFFFF;
        margin-bottom: 0.1rem;
      }
    }
  }
  .time-line {
    display: flex;
    padding: 0 0.4rem;
    .line-box {
      position: relative;
      width: 0.53rem;
      margin-right: 0.32rem;
      display: flex;
      flex-direction: column;
      .circle {
        width: 0.53rem;
        height: 0.53rem;
        background: #62818E;
        border-radius: 50%;
        opacity: 1;
      }
      .circle-center {
        margin: 1.71875rem 0 1.875rem;
      }
      .line {
        position: absolute;
        top: 0.53rem;
        left: 0.26rem;
        width: 1px;
        height: 7.2rem;
        background: #d8d8d8;
        z-index: -1;
      }
    }
    .text {
      .center-box {
        // margin: 1.45rem 0 1.5rem;
      }
      h3 {
        font-size: 0.61rem;
        font-style: italic;
        font-family: Helvetica;
        font-weight: bold;
        line-height: 0.45rem;
        color: #333333;
        margin-bottom: 0.32rem;
      }
      p {
        min-height: 1.5625rem;
        font-size: 0.37rem;
        font-family: Helvetica;
        font-weight: 300;
        line-height: 0.43rem;
        color: #333333;
      }
    }
  }
}
.market-box {
  padding-top: 1.28rem;
  h1 {
    margin-bottom: 0.2rem;
    text-align: center;
    font-size: 0.75rem;
    font-family: Helvetica;
    font-weight: bold;
    line-height: 1.25rem;
    color: #333333;
  }
  .market-card {
    margin: 0 auto 0.2rem;
    padding: 0.29rem 0.45rem;
    width: 9.2rem;
    background: #4E6C77;
    border-radius: 0.2rem;
    h3 {
      margin-bottom: 0.35rem;
      font-size: 0.43rem;
      font-family: Helvetica;
      font-weight: bold;
      line-height: 0.43rem;
      color: #FFFFFF;
    }
    p {
      font-size: 0.37rem;
      font-family: Helvetica;
      font-weight: 300;
      line-height: 0.43rem;
      color: #FFFFFF;
    }
  }
}
</style>