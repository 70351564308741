<template>
  <div>
    <div class="introduction-img">
      <img src="@/assets/imgs/fuwu-8.jpg">
    </div>
    <div :class="['introduction-box', showMore ? '' : 'height-attr']">
      <h1>Immigration and Global Asset Allocation</h1>
      <p>
        Can-Achieve was found in 2008, and joined IPO in 2017, which is the biggest K12 education organization in China. Bright Scholar (NYSE:BEDU), is one of the leading global education and immigration organizations. Can-Achieve Bojiao immigration under Bright Scholar specialized in immigration sector and in serving the high-end people who intend to invest asset overseas. Our services include Overseas Immigration, Overseas Asset Investment, Global Taxes, Overseas Education and Family Inheritance, etc. Can-Achieve Bojiao Immigration is the top professional counselor team, consisting of licensed immigration consultant, former economics counselor of the Immigration Office, International CPA, experienced overseas financial analysts, who specializes in taler-made immigration and study abord planning for the high-end people. The branches of Can-Achieve Bojiao consistently offer more assistance to our clients who have successfully immigrated overseas so that they achieve a greater business.
      </p>
    </div>
    <div class="more-btn" v-show="showMore" @click="clickMore">More</div>
    <div class="point-box">
      <div class="point-item">
        <h3>More Cutting-edge</h3>
        <p>The pioneer of immigration programs.</p>
        <p>Multiple projects launched.</p>
      </div>
      <div class="point-item">
        <h3>More comprehensive</h3>
        <p>Bojiao Immigration, affiliated to Can-Achieve and Bright Scholar, has been committed to overseas study application and international education for ten years, aiming to provide comprehensive education planning for every client’s children.</p>
      </div>
      <div class="point-item">
        <h3>More professional</h3>
        <p>Free counseling service: provide suggestions upon bills and plans, ect.</p>
        <p>Professional advice: on-line answers from professional immigration experts.</p>
      </div>
      <div class="point-item">
        <h3>More reliable</h3>
        <p>Local service.</p>
        <p>Chinese speaker services.</p>
        <p>Overseas legal service.</p>
      </div>
    </div>
    <div class="business-box">
      <h1>Immigration business</h1>
      <div class="content-box">
        <img src="@/assets/imgs/fuwu-usa.jpg">
        <div class="content-text">
          <h3>U.S Immigration</h3>
          <p>
            Can-achieve Bojiao Immigration provide high-quality immigration legal services to the applicant(s) from China.
          </p>
          <p>
            Our specialized immigrant services include EB-1A (Alien of Extraordinary Ability), National Interest Waiver, PERM Labor Certification and other I-140 immigrant petitions.
          </p>
          <p>
            We hard work to build strong relationships based on trust. We know that you want to make America your new dream home, and that always deserves our full attention and our best efforts to realize your goal.
          </p>
        </div>
      </div>
      <div class="content-box">
        <img src="@/assets/imgs/fuwu-ca.jpg">
        <div class="content-text">
          <h3>Canada Immigration</h3>
          <p>Our one-stop platform provides 100+ families with the best company resources and the best school resources, in every detail of study abroad, work, immigration, housing to provide exquisite services. Successfully helped many new immigrants and overseas students to live and work in Canada.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMore: true,
    }
  },
  methods: {
    clickMore() {
      this.showMore = false
    }
  }
}
</script>

<style lang="less" scoped>
div,
p,
h1 {
  box-sizing: border-box;
}
.introduction-img {
  width: 9.12rem;
  height: 11.55rem;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.introduction-box {
  box-sizing: border-box;
  position: relative;
  margin: -3rem auto 0;
  padding: 0.43rem 0;
  width: 100%;
  height: 5rem;
  overflow: hidden;
  background: linear-gradient(180deg, #658A97 0%, #FFFFFF 100%);;
  h1 {
    padding: 0 0.75rem;
    font-size: 0.75rem;
    font-family: Helvetica;
    font-weight: bold;
    line-height: 0.85rem;
    color: #FFFFFF;
    opacity: 1;
  }
  p {
    padding: 0 0.75rem;
    font-size: 0.32rem;
    font-family: Helvetica;
    font-weight: 300;
    line-height: 0.53rem;
    color: #FFFFFF;
    opacity: 1;
  }
}
.more-btn {
  width: 2.27rem;
  height: 0.83rem;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #658A97;
  font-size: 0.53rem;
  font-family: Helvetica;
  font-weight: 300;
  line-height: 0.83rem;
  color: #658A97;
  opacity: 1;
}
.height-attr {
  height: 100%;
  background: #658A97;
}
.point-box {
  padding: 0.5rem 0 0.72rem;
  width: 100%;
  .point-item {
    margin: 0 auto 0.27rem;
    padding: 0.5rem 0.45rem;
    width: 9.2rem;
    background: #6F97A5;
    border-radius: 0.2rem;
    opacity: 1;
    h3 {
      font-size: 0.43rem;
      font-family: Helvetica;
      font-weight: bold;
      line-height: 0.43rem;
      color: #FFFFFF;
      margin-bottom: 0.2rem;
    }
    p {
      font-size: 0.37rem;
      font-family: Helvetica;
      font-weight: 300;
      line-height: 0.43rem;
      color: #FFFFFF;
    }
  }
}
.business-box {
  h1 {
    margin-bottom: 0.85rem;
    text-align: center;
    font-size: 0.75rem;
    font-family: Helvetica;
    font-weight: bold;
    line-height: 0.85rem;
    color: #333333;
  }
  .content-box {
    display: flex;
    padding: 0 0.29rem 0.67rem;
    img {
      width: 1.45rem;
      height: 1.47rem;
      margin-right: 0.4rem;
    }
    .content-text {
      h3 {
        margin-bottom: 0.32rem;
        font-size: 0.43rem;
        font-family: Helvetica;
        font-weight: bold;
        line-height: 0.43rem;
        color: #6F97A5;
      }
      p {
        font-size: 0.32rem;
        font-family: Helvetica;
        font-weight: 300;
        line-height: 0.48rem;
        color: #333333;
      }
    }
  }
}
</style>