<template>
  <div class="banner-box">
    <div class="arrow-box swiper-button-prev">
      <img src="@/assets/logos/swiperjiantou.png" width="100%" />
    </div>
    <div class="arrow-box arrow-box-right swiper-button-next">
      <img src="@/assets/logos/right.png" width="100%" />
    </div>
    <div class="swiper-container">
      <ul class="swiper-wrapper">
        <li class="swiper-slide" v-for="(item,index) in list" :key="index">
          <!-- <img :src="item.icon" alt=""> -->
          <img src="../../../assets/logos/study-follow-icon1.png" alt="">
          <p>{{item.title}}</p>
          <div class="mask-box">
            <div class="mask-title">{{item.title}}</div>
            <div class="mask-content">{{item.content}}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      list: [
        {
          icon: "../../../assets/logos/study-follow-icon1.png",
          title: 'Provide professional study plans',
          content: 'Based on the enquiry and essential information provided by our partners, our marketing/consulting and admission team will provide tailored study plans for each case. This also includes guidance on the possibility of acceptance as well as the material list for each application.',
        },
        {
          icon: "../../../assets/logos/study-follow-icon2.png",
          title: 'Provide one-on-one support',
          content: 'Our experienced marketing/consulting team are some of the most well-trained experts in international education. They will provide multiple study solutions for complicated cases in different countries.',
        },
        {
          icon: "../../../assets/logos/study-follow-icon1.png",
          title: 'Provide the material list accurately',
          content: 'With over tens of thousands of cases’ experience, our dedicated team are able to share an accurate material list for each application. Students can feel assured to obtain their offer under our extremely professional support.',
        },
        {
          icon: "../../../assets/logos/study-follow-icon2.png",
          title: 'Recommend the best choices for subject and school',
          content: 'As we work extremely closely with our partnered institutions, we are able to recommend the best choice regarding the subject and school for each student.',
        },
        {
          icon: "../../../assets/logos/study-follow-icon1.png",
          title: 'Review and submit the applications',
          content: 'We have a thorough system to review all the application from our partners. Our admission team will inform our agents of the problems in the application once they have spotted them in this system. This will ensure all the application packages submitted from us will be complete and professionally presented.',
        },
        {
          icon: "../../../assets/logos/study-follow-icon2.png",
          title: 'Feedback of the result for applications',
          content: 'We will always keep our partners posted regarding the application status, so they can keep tracking the update.',
        }
      ]
    }
  },
  methods: {
    init() {
      let _that = this;
      this.$nextTick(() => {
        this.mySwiper = new Swiper(".swiper-container",{
          autoplay: false,
          slidesPerView: 'auto',
          initialSlide: 0,
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
        })
      })
    },       
  },
  mounted() {
    this.init();
  },
}
</script>

<style lang="less" scoped>
.banner-box {
  position: relative;
  width: 100%;
  // padding-left: 280px;
  .arrow-box {
    position: absolute;
    top: 257px;
    left: -139px;
    height: 85px;
    width: 85px;
    background: #81cbd8;
    border-radius: 50%;
    text-align: center;
    line-height: 117px;
    z-index: 1;
    img {
      width: 40px;
      height: 40px;
      margin-left: -9px;
    }
  }
  .arrow-box-right {
    background: #fff;
    left: 875px;
    z-index: 999;
    img {
      transform: translate(-180edg);
      margin-left: 9px;
    }
  }
  .swiper-container {
    overflow: hidden;
    ul {
      display: flex;
      width: 2166px;
      li {
        padding: 158px 38px 0;
        box-sizing: border-box;
        width: 430px !important;
        height: 643px;
        margin-right: 38px;
        border-radius: 10px;
        background: #80CBD8;
      }
    }
  }
}
.swiper-slide {
  position: relative;
  text-align: center;
  img {
    width: 128px;
    height: 142px;
  }
  p {
    margin-top: 79px;
    font-size: 36px;
    font-family: Helvetica;
    line-height: 42px;
    color: #FFFFFF;
  }
}
.swiper-slide:hover .mask-box {
  // display: block;
  // transform: translateY(0);
  top: 0;
}
.mask-box {
  position: absolute;
  top: 643px;
  left: 0;
  // display: none;
  box-sizing: border-box;
  width: 430px;
  height: 643px;
  background: #62818E;
  padding: 72px 23px 0;
  border-radius: 10px;
  transition: top 0.5s;
  text-align: left;
  .mask-title {
    font-size: 36px;
    font-family: Helvetica;
    font-weight: bold;
    line-height: 42px;
    color: #FFFFFF;
    margin-bottom: 50px;
  }
  .mask-content {
    font-size: 24px;
    font-family: Helvetica;
    font-weight: 300;
    line-height: 36px;
    color: #FFFFFF;
  }
}
</style>